@import "tailwindcss/base";

@import "tailwindcss/components";

@import "tailwindcss/utilities";

@import '/common/styles.css';
/* @import "site.css"; */

@font-face {
    font-family: "Atak";
    src: url("../fonts/Atak.otf");
  }
/* @import "./site.css"; */
